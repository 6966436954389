import { UseMutationOptions, useMutation } from 'react-query';

import { VALIDATE_TASK_TOKEN } from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

type ValidateTokenParams = {
  token: string;
  template_slug?: string;
};

type Options = UseMutationOptions<any, any, ValidateTokenParams, unknown>;

export const useValidateToken = (options: Options = {}) =>
  useMutation({
    mutationFn: ({ token, template_slug }: ValidateTokenParams) =>
      enhancedAxios({
        url: VALIDATE_TASK_TOKEN,
        method: 'POST',
        data: {
          token,
          template_slug: template_slug,
        },
      }),
    ...options,
  });
