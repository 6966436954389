import {
  DisplayProps,
  FlexboxProps,
  PositionsProps,
  SizingProps,
  SpacingProps,
  TypographyProps,
  display,
  flexbox,
  positions,
  sizing,
  spacing,
  typography,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { Colors, Paragraphs, colors } from '@hl-portals/constants';

import { paragraphHelper } from '@hl-portals/helpers';

export const Title = styled.h1<TypographyProps & SpacingProps>`
  font-weight: 600;
  line-height: 1.3;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.darkBlue};
  ${typography};
  ${spacing};
`;

export type ParagraphProps = {
  color?: Colors | string;
  variant?: Paragraphs;
  truncate?: boolean;
  opacity?: string;
  cursor?: 'pointer' | 'default' | 'not-allowed';
  textTransform?: 'capitalize' | 'lowercase' | 'uppercase' | 'initial';
  lineHeight?: string;
  underline?: boolean;
  align?: string;
  css?: { [property: string]: any };
} & Partial<SpacingProps> &
  Partial<SizingProps> &
  Partial<DisplayProps> &
  Partial<TypographyProps> &
  Partial<PositionsProps>;

export type ParagraphType = ParagraphProps &
  SpacingProps &
  SizingProps &
  DisplayProps &
  TypographyProps &
  FlexboxProps &
  PositionsProps;

export const Paragraph = styled.p<ParagraphType>`
  ${spacing}
  ${sizing}
  ${display}
  ${typography}
  ${flexbox}
  ${positions}
  ${({
    theme,
    variant = 'text',
    color = 'darkBlue',
    truncate,
    maxWidth,
    opacity,
    cursor,
    underline,
    textTransform,
    lineHeight = '1.4',
    fontWeight,
  }) => {
    const { paragraphs } = theme;
    const {
      color: themeColor,
      fontWeight: themeFontWeight,
      fontSize,
    } = paragraphs[variant] ?? paragraphs.text;
    return css`
      box-sizing: border-box;
      color: ${color ? theme.colors[color as Colors] || color : themeColor};
      font-weight: ${fontWeight || themeFontWeight};
      font-size: ${fontSize};
      line-height: ${lineHeight};
      ${textTransform && `text-transform: ${textTransform};`}
      ${cursor && `cursor: ${cursor};`}
      ${opacity && `opacity: ${opacity};`}
      ${underline && `text-decoration: underline;`}
      ${truncate && maxWidth
        ? `
          max-width: ${maxWidth};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `
        : ''}
    `;
  }}
  strong {
    font-weight: 800;
  }
`;

export const IndentedText = styled(Paragraph)<{
  indentation?: string;
  bulletColor?: Colors;
  bulletPosition?: 'top' | 'center';
  hideBullet?: boolean;
}>`
  ${({
    indentation,
    bulletColor = 'darkBlue',
    bulletPosition = 'top',
    hideBullet,
  }) => css`
    margin-left: ${indentation || '40px'};
    padding-left: 16px;
    position: relative;

    ${!hideBullet &&
    css`
      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${colors[bulletColor]};
        position: absolute;
        top: ${bulletPosition === 'top' ? '8px' : 'calc(50% - 2px)'};
        left: 0;
      }
    `}
  `}
`;

export const Footnote = styled(Paragraph).attrs({ as: 'span' })<{
  note: string;
  noteSize?: string;
}>`
  ${({ noteSize = '10px', note }) => css`
    margin-right: 4px;
    position: relative;

    &::before {
      content: '${note}';
      position: absolute;
      top: -4px;
      right: -6px;
      font-size: ${noteSize};
    }
  `}
`;

export type ListType = { variant: Paragraphs } & SpacingProps &
  SizingProps &
  DisplayProps;

export const List = styled.ul<ListType>`
  list-style: disc;
  line-height: 20px;
  ${({ theme, variant }) => css`
    ${paragraphHelper({ type: variant, theme })};
    color: ${theme.colors.darkBlue};
  `};
  ${spacing}
  ${sizing}
  ${display}
`;
