import {
  DisplayProps,
  FlexboxProps,
  SizingProps,
  SpacingProps,
  TypographyProps,
  display,
  flexbox,
  sizing,
  spacing,
  typography,
} from '@mui/system';
import styled, { css } from 'styled-components';

import { ButtonSizes, Buttons, buttons } from '@hl-portals/constants';

import { CSSVariants, addMediaQueries } from '@hl-portals/helpers';

import Spinner from '../Spinner';

export type ButtonVariant = 'text' | 'contained' | 'outlined' | 'branded';

interface BaseProps {
  size?: CSSVariants<ButtonSizes>;
  rounded?: boolean;
  slim?: boolean;
  color?: Buttons;
  variant?: ButtonVariant;
  disabled?: boolean;
  isLoading?: boolean;
  brandColors?: {
    primary: string;
    secondary: string;
  };
}

export const ButtonSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  margin-top: -8px;

  &::after {
    width: 16px;
    height: 16px;
  }
`;

const ButtonSizesCss = (theme) =>
  Object.keys(buttons.sizes).reduce(
    (acc, size) => ({
      ...acc,
      [size]: css`
        padding: ${theme.buttons.sizes[size].padding};
        border-radius: ${theme.buttons.sizes[size].borderRadius};
        line-height: ${theme.buttons.sizes[size].lineHeight};
        font-size: ${theme.buttons.sizes[size].fontSize};
      `,
    }),
    {}
  );

const ButtonVariantCss = (props) => {
  const useBrandColors = props.brandColors && props.color !== 'tertiary';

  return {
    contained: css`
      color: ${({ theme }) =>
        useBrandColors ? theme.colors.white : theme.buttons[props.color].color};
      background-color: ${({ theme }) =>
        useBrandColors
          ? props.brandColors.primary
          : theme.buttons[props.color].backgroundColor};

      ${Boolean(props.disabled) === false &&
      css`
        &:hover,
        &:focus {
          box-shadow: ${({ theme }) =>
            theme.buttons[props.color].hover.boxShadow};
          background-color: ${({ theme }) =>
            useBrandColors
              ? props.brandColors.primary
              : theme.buttons[props.color].hover.backgroundColor};
          ${props.brandColors ? 'opacity: .9;' : ''}
        }
      `}
    `,
    text: css`
      color: ${({ theme }) =>
        props.color === 'tertiary'
          ? useBrandColors
            ? props.brandColors.secondary
            : theme.buttons[props.color].color
          : useBrandColors
          ? props.brandColors.primary
          : theme.buttons[props.color].backgroundColor};
      background-color: transparent;
      border: none;

      &:focus {
        background-color: ${({ theme }) =>
          `${
            props.color === 'tertiary'
              ? useBrandColors
                ? props.brandColors.secondary
                : theme.buttons[props.color].color
              : useBrandColors
              ? props.brandColors.primary
              : theme.buttons[props.color].backgroundColor
          }0D`};
      }

      ${Boolean(props.disabled) === false &&
      css`
        &:hover {
          background-color: ${({ theme }) =>
            `${
              props.color === 'tertiary'
                ? theme.buttons[props.color].color
                : theme.buttons[props.color].backgroundColor
            }0D`};
        }
      `}
    `,
    outlined: css`
      color: ${({ theme }) =>
        useBrandColors
          ? props.brandColors.primary
          : theme.buttons[props.color].color};
      background-color: ${({ theme }) =>
        useBrandColors
          ? theme.colors.white
          : theme.buttons[props.color].backgroundColor};
      border: 1px solid ${({ theme }) =>
        useBrandColors
          ? props.brandColors.primary
          : theme.buttons[props.color].borderColor}};
  
      &:focus {
        background-color: ${({ theme }) =>
          `${
            useBrandColors
              ? `${props.brandColors.primary}20`
              : theme.buttons[props.color].focus.backgroundColor
          }`};
        border: 1px solid ${({ theme }) =>
          useBrandColors
            ? props.brandColors.primary
            : theme.buttons[props.color].borderColor};
      }
  
      ${
        Boolean(props.disabled) === false &&
        css`
        &:hover {
          box-shadow: ${({ theme }) =>
            theme.buttons[props.color].hover.boxShadow};
          background-color: ${({ theme }) =>
            useBrandColors
              ? theme.colors.coolGray5
              : theme.buttons[props.color].hover.backgroundColor};
          border: 1px solid ${({ theme }) =>
            useBrandColors
              ? props.brandColors.primary
              : theme.buttons[props.color].borderColor}};
        }
      `
      }
    `,
  };
};

export const ButtonWrapper = styled.button<
  BaseProps &
    SpacingProps &
    SizingProps &
    DisplayProps &
    FlexboxProps &
    TypographyProps
>`
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  cursor: pointer;
  transition: 150ms ease;
  transition-property: backgorund-color, color, border-color, box-shadow;

  ${spacing}
  ${sizing}
  ${display}
  ${flexbox}


  --border-size: ${({ theme, color, rounded }) =>
    theme.buttons[color].borderSize};
  border: ${({ theme, color }) => theme.buttons[color].border};
  border-width: var(--border-size);

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme, color }) => theme.buttons[color].disabled.color};
    background-color: ${({ theme, color }) =>
      theme.buttons[color].disabled.backgroundColor};
  }

  ${({ size, theme }) =>
    addMediaQueries({ theme, prop: size, styles: ButtonSizesCss })}
  ${(props) => ButtonVariantCss(props)[props.variant]}

  ${({ isLoading, color, theme }) =>
    isLoading
      ? `
        color: transparent;
        cursor: default;
        background-color: ${theme.buttons[color].backgroundColor};
        &:hover{
          background-color: ${theme.buttons[color].backgroundColor};
        }
        svg, path {
          fill: transparent
        }
        `
      : ''}

      
  ${({ theme, disabled, color }) =>
    Boolean(disabled) === false
      ? css`
          &:hover {
            border: ${theme.buttons[color].border};
          }

          &:focus {
            outline: none;
            box-shadow: ${theme.buttons[color].focus.boxShadow};
            border: ${theme.buttons[color].border};
          }
        `
      : css`
          cursor: not-allowed;
          color: ${theme.buttons[color].disabled.color};
          background-color: ${theme.buttons[color].disabled
            .backgroundColor} !important;
        `}

    ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 200px;
    `}
    ${({ slim }) =>
    slim &&
    css`
      padding: 8px 24px;
      line-height: 1.6;
    `}


  ${typography};
`;
