import styled from 'styled-components';

import { Box, BoxTypes } from '../Box';

const Root = styled(Box)`
  position: relative;

  &:hover {
    .content {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 32px);
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }
  }

  .trigger {
    cursor: pointer;
  }

  .content {
    padding: 24px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 0);
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 14px 48px 0px rgba(108, 129, 171, 0.2);
  }
`;

const Trigger = (props: BoxTypes) => <Box className="trigger" {...props} />;
const Content = (props: BoxTypes) => <Box className="content" {...props} />;

export const Popover = {
  Root,
  Trigger,
  Content,
};
