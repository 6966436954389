import { DisplayProps, SpacingProps, display, spacing } from '@mui/system';
import styled, { css } from 'styled-components';

interface AvatarWrapperProps {
  size: 'small' | 'medium' | 'big' | 'extraBig' | 'xxl' | 'xxxl' | number;
  type: 'normal' | 'alert' | 'image';
  surface: 'dark' | 'light' | 'blue' | 'none' | 'alert';
  squared?: boolean;
  reverse?: boolean;
  fontSize?: number;
}

type AvatarWrapperType = AvatarWrapperProps & SpacingProps & DisplayProps;

export const AvatarWrapper = styled.div<AvatarWrapperType>`
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  user-select: none;
  text-transform: uppercase;
  font-weight: bold;
  ${spacing}
  ${display}
  ${({ theme, size, type, surface, squared, reverse, fontSize }) => {
    const { size: sizeValue, fontSize: fontSizeValue } = theme.avatar.size[
      size
    ] ?? {
      size,
      fontSize: fontSize ?? 16,
    };
    const { color, backgroundColor } = theme.avatar.surface[surface];
    const isAlert = type === 'alert';
    const isReverse = reverse && !isAlert;
    return css`
      border-radius: ${squared ? '3px' : '50%'};
      width: ${sizeValue}px;
      height: ${sizeValue}px;
      font-size: ${fontSizeValue}px;
      color: ${isReverse ? theme.colors.darkBlue : color};
      background-color: ${reverse
        ? theme.colors.white
        : theme.colors.electricBlue};
      ${isAlert ? `background-color: ${theme.colors.cadmiumRed};` : ''}
      ${isReverse ? `border: 1px solid ${theme.colors.darkBlue};` : ''}
    `;
  }}

  &img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
  }
`;

export const AvatarImg = styled.img`
  object-fit: cover;
`;
