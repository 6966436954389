import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SlideDown } from '../../Animations';
import { Box, BoxTypes } from '../../Box';
import { Input, InputProps } from '../../Input';
import InputGroup, { InputGroupProps } from '../../InputGroup';
import { ParagraphProps } from '../../Typography';
import { InputError } from '../components/input-error';

type InputWrapperProps = {
  label?: React.ReactNode;
  labelProps?: ParagraphProps & BoxTypes;
  optional?: boolean;
} & BoxTypes;

const InputWrapper = ({
  children,
  label,
  labelProps,
  optional,
  ...props
}: InputWrapperProps) => {
  if (label) {
    return (
      <InputGroup
        {...props}
        label={label}
        labelProps={labelProps}
        flexDirection="column"
        optional={optional}
      >
        {children}
      </InputGroup>
    );
  }
  return (
    <Box flexDirection="column" flex="1" {...props}>
      {children}
    </Box>
  );
};

export type TextInputProps = {
  name: string;
  label?: React.ReactNode;
  labelProps?: ParagraphProps & BoxTypes;
  optional?: boolean;
  autofocus?: boolean;
  containerProps?: BoxTypes & InputGroupProps;
  testId?: string;
  tooltip?: string;
  wrapperProps?: BoxTypes;
  note?: string;
  mode?: 'onBlur' | 'onChange';
} & InputProps;

export const TextInput = ({
  name,
  label,
  labelProps,
  placeholder,
  optional,
  autofocus,
  containerProps,
  testId,
  tooltip,
  wrapperProps,
  note,
  mode = 'onChange',
  ...props
}: TextInputProps) => {
  const {
    register,
    formState,
    setValue,
    setFocus,
    clearErrors,
    watch,
    trigger,
  } = useFormContext();

  const error = formState.errors[name];
  const value = watch(name);
  const phoneMask = '(___) ___-____';
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (autofocus) setFocus(name);
  }, [autofocus, name, setFocus]);

  const showError = !!error && (mode === 'onChange' || !touched);

  return (
    <InputWrapper
      label={label}
      optional={optional}
      labelProps={labelProps}
      note={note}
      flex="1"
      {...containerProps}
    >
      <Input
        type="text"
        placeholder={placeholder}
        isError={showError}
        onClear={value ? () => setValue(name, '') : undefined}
        data-test={testId}
        onFocus={() => setTouched(true)}
        {...register(name, {
          onChange(e) {
            const { value: inputValue } = e.target;

            if (inputValue && inputValue !== phoneMask) {
              clearErrors(name);
            }
          },
          onBlur() {
            if (mode === 'onBlur') {
              trigger(name);
            }
            setTouched(false);
          },
        })}
        {...props}
      />
      {showError && (
        <SlideDown>
          <InputError error={error} />
        </SlideDown>
      )}
    </InputWrapper>
  );
};
